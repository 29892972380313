<script setup>

import Heading from "~/components/atoms/Heading.vue";
import Text from "~/components/atoms/Text.vue";
import Button from "~/components/atoms/Button.vue";
import LabelIcon from "~/components/icons/LabelIcon.vue";
import CopyIcon from "~/components/icons/CopyIcon.vue";
import {useCartStore} from "~/store/cartStore.js";
import * as Sentry from "@sentry/nuxt";

const cartStore = useCartStore();

const props = defineProps({
  error: Object
})

useHead({
  title: props.error.message,
})

onMounted(() => {
  cartStore.getContent()

  Sentry.captureException(props.error);
})

const handleError = () => clearError({redirect: '/'})
const coupon = 'IAMLUCKY400';
const coupon500 = 'IAMLUCKY500';

</script>

<template>
  <NuxtLayout>
    <br>
    <div class="container g-16">
      <div class="form-container center-auto g-24">
        <Heading tag="h2">{{ error.statusCode }}</Heading>
        <Text text-alignment="center" tag="strong" class="title">{{ 'Internal Error' }}
        </Text>
        <Text text-alignment="center">We are very sorry for inconvenience. It looks you are trying to
          access a page that has been deleted, moved or never been
          existed.
        </Text>
        <Button type="black" class="full-width tall" @click="handleError"> RETURN TO HOME PAGE</Button>
      </div>
<!--      <div class="form-container center-auto g-24">-->
<!--        <Text tag="strong" class="coupon-title">-->
<!--          <LabelIcon stroke="black"/>-->
<!--          Här är rabattkod 3% som små kompensation :)-->
<!--        </Text>-->
<!--        <div class="row full-width flex-center g-8">-->
<!--          <div class="coupon" style="height: 56px;">{{ error.statusCode == 500 ? coupon500 : coupon }}</div>-->
<!--          <Button :check-after-click="true" type="black tall" v-copy="coupon">-->
<!--            <CopyIcon stroke="white"/>&nbsp;Kopiera-->
<!--          </Button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <br>
  </NuxtLayout>
</template>

<style scoped>

.coupon {
  flex: 1;
  border: 2px dashed #dfd9d9;
  text-align: center;
  font-size: 16px;
  height: 36px;
  justify-content: center;
}

.coupon-title {
  font-size: 16px;
}

.form-container {
  border: 1px solid #dfd9d9;
  padding: 30px;
  max-width: 600px;
}

h2 {
  color: #fcc200;
  font-size: 64px;
  font-weight: 600;
  text-align: center;
}

.title {
  font-size: 24px;
}
</style>
